import { useEffect, useState } from "react";
import Project from "./project/Project";
import { useLocation, useNavigate } from 'react-router-dom';


const Projects = (props) =>{
    

    const [showProjects, setShowProjects] = useState(false);

    const navigate = useNavigate();

    const location = useLocation();

    useEffect(()=>{
        if(props.showall === true){
            setShowProjects(true)
            setTimeout(() => scrollToTop(), 200);
            
        }else{
            setShowProjects(false)
    
        }
        const elementId = location.hash.replace('#', '');

        setTimeout(()=>{
            if (elementId) {
                const element = document.getElementById(elementId);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        },200)
        
    },[props.showall, location])


    const skillStyle = {
        marginTop: '80px'
    };

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    const path_location = window.location.pathname;

    //Beko
    const beko_des = "I’ve worked on creating promotional landing pages for Beko, one of the top three large home appliance brands in Europe. My role involved developing two product-focused landing pages using HTML, CSS, and JavaScript, ensuring a clean and engaging design to support their marketing efforts."


    //AON
    const now = new Date();
    const start_aon = new Date("2023-01-01");
    const totalMonths_aon = (now.getFullYear() - start_aon.getFullYear()) * 12 + now.getMonth() - start_aon.getMonth() + 1;
    const years_aon = Math.floor(totalMonths_aon / 12);
    const months_aon = totalMonths_aon % 12;
    const formattedDifference_aon = `${years_aon} yr ${months_aon} mos`;
    const aon_des = "I'm involved in the 'Annals of Nursing' website project, a medical journal platform for nursing research. My role is centered on front-end development, utilizing JavaScript, CSS3, HTML5, and jQuery to craft a user-friendly and visually appealing interface. The rest of the team focuses on backend development with Laravel and MySQL, ensuring the site's reliability and efficiency."

    //Flowcandy
    const start = new Date("2022-05-08");
    const totalMonths = (now.getFullYear() - start.getFullYear()) * 12 + now.getMonth() - start.getMonth() + 1;
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;
    const formattedDifference = `${years} yr ${months} mos`;
    const fc_des = "As a developer and IT specialist at FlowCandy, my role encompasses a diverse range of responsibilities. I'm primarily engaged in implementing and maintaining Klaviyo campaigns and flows, but my involvement extends to the research and development team where I've contributed to several innovative projects. These include the development of an interactive shopping cart using AMP emails, crafting countdown timers customized for emails, managing our company's website using Webflow, and integrating various other marketing technologies to enhance our operational efficiency."

    //Icben
    const icben_des = "As the Website and Platform Architect, Web Designer, and Front-end Developer for the 14th ICBEN Congress on Noise as a Public Health Problem, I dedicated a year and a half to creating a website that was not only user-friendly but also comprehensive. This specialized platform simplified the process of submitting abstracts and scientific papers and included a convenient online payment system. It was designed with the unique needs of the medical congress in mind, ensuring a smooth and efficient experience for all participants. My role was central to bringing this tailored digital solution to life.";

    //Dambel estates
    const de_des = "In this personal project, I've developed a real estate web application using Next.js, showcasing my web development skills. I've integrated technologies such as Prisma and Node.js for a smooth backend experience, and Google Auth 2 for easy user registration. The app features an intuitive design for browsing and managing real estate listings, complete with interactive maps, advanced search filters, and options for users to manage their own listings. A robust admin panel supports these functions, ensuring a seamless and engaging user experience."

    //Fitpass
    const fit_des="The Fitpass Training App, a personal project created as an Android app demo, simplifies the gym-going experience with its user-friendly interface in Android Studio. It features a comprehensive database of fitness facilities, enhanced with details like location, type, and user ratings. Its standout function is a QR code scanning system that debits credits from a user's account upon gym entry, coupled with a demo payment section for easy credit replenishment. This app is especially useful for fitness enthusiasts looking for a convenient way to access and manage their gym visits."


    //DD Air
    const ddair_des="In this project, I created a personal flight booking application, blending technologies like Angular, PHP, and Python to simplify air travel management. It offers a range of features, such as browsing and filtering flights, booking seats, and easy cancellation options. The highlight is its Rasa chatbot, which allows users to handle key tasks through chat commands, making the process more interactive and user-friendly. This app reflects my commitment to developing practical, user-centric solutions in the travel domain."

    //DD wallet
    const ddwallet_des = "I developed a Personal Wallet Web Application as a personal project to streamline finance management. The application simplifies creating and managing multiple accounts in different currencies, including RSD and EUR. It enables users to effortlessly record and categorize incomes and expenses, providing a clear overview of financial activities. With customizable categories, the app allows for personalized financial analysis and offers in-depth statistics for a better understanding of spending patterns. It also includes features to track monthly and yearly profits, all within a user-friendly interface, making managing finances an efficient and enjoyable experience."

    //Paradiso
    const paradiso_des = "The whole idea behind this app was to keep things simple and user-friendly. You can bookmark your favorite apartments to find them easily later, and when you're ready to make a decision, booking is just a few taps away. I made sure to include a login feature for booking, to keep your details safe. And hey, if you change your mind, canceling is hassle-free. I wanted to create something useful, easy to navigate, and helpful for anyone looking to find a great spot for their next vacation."

    //Cosmopolitan Montenegro
    const start_cos = new Date("2021-06-01");
    const totalMonths_cos = (now.getFullYear() - start_cos.getFullYear()) * 12 + now.getMonth() - start_cos.getMonth() + 1;
    const years_cos = Math.floor(totalMonths_cos / 12);
    const months_cos = totalMonths_cos % 12;
    const formattedDifference_cos = `${years_cos} yr ${months_cos} mos`;
    const cos_des = "In developing the website for this lifestyle and business management company, my role focused on front-end development and web design, using JavaScript and jQuery. My aim was to create a user-friendly and functional site that clearly presents the company's services, including custom vacations and corporate retreats. The design was kept clean and intuitive, ensuring easy navigation for various users, from individual travelers to corporate clients. By integrating JavaScript and jQuery, I enhanced the site's interactivity and responsiveness, making it accessible across different devices and browsers."


    //Click Unique
    const click_des = "In my early web development career, I created Click Unique, a website designed to attract new clients by showcasing my services and recent projects, complete with live previews for desktop and mobile. It featured a unique project evaluation page where clients could propose budgets and needs. Focusing on user experience, the site offered bilingual options in Serbian and English and a Dark Mode for visual comfort. Responsive and straightforward, Click Unique was a testament to my commitment to creating accessible and user-friendly web solutions."

    //Avalske
    const av_des = "My first professional web project, Avalaske Doline, is a real estate platform showcasing luxurious homes in Serbia. It provides detailed property insights and features an interactive gallery for virtual tours. Developed with HTML, CSS, JavaScript, and JQuery, the site is a model of modern web design, offering a responsive and engaging user experience. Avalaske Doline represents not just a real estate listing, but a showcase of Serbia's luxury homes."



    return (
        <div className='margin_around'>
        <div className="main_section about_section relative_section" style={!showProjects ? undefined : skillStyle}>
            <div id="projects">

            </div>

            <div className="about_wrapper">

            <div className="skill_title_wrapper">
                {showProjects &&
                    <div className="back_skills" onClick={()=>
                    {
                        navigate('/')
                        props.backToProjects()
                    }
                    }>
                        <span className="fa-solid fa-arrow-left"></span>
                    </div>
                }
                <p className="section_title">Projects</p>
            </div>


                <Project path={path_location} id="beko" url_path={"/work/beko"} image={"img/beko.webp"} title={"Beko - Landing Pages"} position={"Front-end developer"} date={"Project based"} year={"· 2023/2024"} location={"Belgrade, Serbia"} description={beko_des} skills={"JavaScript · CSS3 · HTML5"}/>


                <hr className="projects_hr"/>


                <Project path={path_location} id="aon" url_path={"/work/aon"} image={"img/nursing.webp"} title={"Annals of Nursing Website"} position={"Front-end developer, Website maintainer and Web designer"} date={"Jan 2022 - Present"} year={"· " + formattedDifference_aon} location={"Belgrade, Serbia"} description={aon_des} skills={"JavaScript · CSS3 · HTML5 · jQuery"}/>


                <hr className="projects_hr"/>

                <Project path={path_location} id="flowcandy" url_path={"/work/flowcandy"} image={"img/fc-transparent.webp"} title={"FlowCandy Projects"} position={"Software Developer"} date={"Sep 2022 - Present"} year={"· " + formattedDifference} location={"United States, Remote"} description={fc_des} skills={"PHP · AMP · Klaviyo · Webflow · Shopify · JavaScript · MySQL"}/>


                <hr className="projects_hr"/>

                <Project path={path_location} id="icben" url_path={"/work/icben2023"} image={"img/icben.webp"} title={"Icben 2023"} position={"Website developer and Web Designer"} date={"Jan 2022 - Jul 2023"} year={"· 1 yr 7 mos"} location={"Belgrade, Serbia"} description={icben_des} skills={"JavaScript · CSS3 · HTML5 · jQuery"}/>

                {showProjects &&
                    
                    <div>

                        <hr className="projects_hr"/>
                        
                        <Project path={path_location} id="fitpass" url_path={"/work/fitpass"} image={"img/fitpass.webp"} title={"Fitpass(Demo)"} position={"Application Developer"} date={"May 2023"} year={""} location={"Personal Project"} description={fit_des} skills={"Java · XML · Android Studio · API · MySQLite"} />


                        <hr className="projects_hr"/>

                        <Project path={path_location} id="realestates" url_path={"/work/realestates"} image={"img/de_logo.webp"} title={"Real Estates Project"} position={"Front-end developer and Back-end developer"} date={"Feb 2023"} year={""} location={"Personal Project"} description={de_des} skills={"TypeScript · Next JS · Prisma · Node JS · Google Auth 2.0 · Mongo DB"}/>

                        <hr className="projects_hr"/>

                        <Project path={path_location} id="flight" url_path={"/work/flight"} image={"img/ddair.webp"} title={"Flight Booking Project"} position={"Front-end developer, Back-end developer and Web designer"} date={"Nov 2022"} year={""} location={"Personal Project"} description={ddair_des} skills={"TypeScript · CSS3 · Angular JS · Angular Material · PHP · MySQL · Rasa chatbot - Python"}/>

                        <hr className="projects_hr"/>


                        <Project path={path_location} id="wallet" url_path={"/work/wallet"} image={"img/wallet.webp"} title={"Personal Web Wallet"} position={"Web designer, Front-end developer and Back-end developer"} date={"Jun 2022"} year={""} location={"Personal Project"} description={ddwallet_des} skills={"HTML5 · CSS3 · JavaScript · PHP · Smarty · MySQL"}/>

                        <hr className="projects_hr"/>

                        
                        <Project path={path_location} id="travel" url_path={"/work/travel"} image={"img/paradiso.webp"} title={"Travel Agency Project"} position={"Front-end developer, Back-end developer and Web designer"} date={"Jan 2022"} year={""} location={"Personal Project"} description={paradiso_des} skills={"TypeScript · CSS3 · Angular JS · Angular Material"}/>

           
                        <hr className="projects_hr"/>

                        <Project path={path_location} id="cosmopolitan" url_path={"/work/cosmopolitan"} image={"img/cosmopolitan.webp"} title={"Cosmopolitan Montenegro"} position={"Front-end developer, Website maintainer and Web designer"} date={"Jun 2021 - Present"} year={"· " + formattedDifference_cos} location={"Podgorica & Budva, Montenegro"} description={cos_des} skills={"JavaScript · CSS3 · HTML5 · jQuery"}/>


                        <hr className="projects_hr"/>

                        <Project path={path_location} id="clickunique" url_path={"/work/clickunique"} image={"img/click.webp"} title={"Click Unique"} position={"Web designer and Front-end developer"} date={"Nov 2020"} year={""} location={"Belgrade, Serbia"} description={click_des} skills={"HTML5 · CSS3 · JavaScript · jQuery · PHP"}/>

                        <hr className="projects_hr"/>

                        <Project path={path_location} id="avalskedoline" url_path={"/work/avalskedoline"} image={"img/avalske.webp"} title={"Avalske Doline"} position={"Web designer and Front-end developer"} date={"Jul 2020 - Aug 2021"} year={"· 1 yr 1 mos"} location={"Belgrade, Serbia"} description={av_des} skills={"HTML5 · CSS3 · JavaScript · jQuery · PHP"}/>


                    </div>
                    
                }

        </div>

            {!showProjects &&
                <div className="all_projects" onClick={()=>navigate('/work')}>
                    <p>Show all 11 Projects <span className="fa-solid fa-arrow-right"></span></p>
                </div>
            }

        </div>
        </div>
    )
}


export default Projects;