import React, { useCallback, useEffect, useState } from 'react';

const Admin = ({ component: Component, ...rest }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const [totalViews, setTotalViews] = useState(0);

    const [todayViews, setTodayViews] = useState(0);

    if(isLoggedIn === false){
            fetch('https://backend.dambel.dev/api/check-session', {
                method: 'GET',
                credentials: 'include'
            })
            .then(res => res.json())
            .then(data => {
                console.log("LOGED IN: " + isLoggedIn)
                console.log("sERVER CHECK LOGED IN: " + data.loggedIn)
                if(isLoggedIn !== data.loggedIn){
                    setIsLoggedIn(data.loggedIn)
                }

                console.log("LOGED IN AGAIN: " + isLoggedIn)

                // else{
                //     window.location.href = "/"
                // }
            })
            .catch(() => setIsLoggedIn(false));
    }

    // if(isLoggedIn === null){
    //     fetch('https://backend.dambel.dev/api/check-session')
    //     .then(res => res.json())
    //     .then(data => {
    //         setIsLoggedIn(data.loggedIn)
    //         console.log(data.loggedIn)
    //         if(!data.loggedIn){
    //             window.location.href = "/"
    //         }
    //     })
    //     .catch(() => setIsLoggedIn(false));
    // }else{
    //     if(!isLoggedIn){
    //         window.location.href = "/"
    //     }
    // }


    //Get all views
    const showTotalViews = useCallback( async () => {
        try {
            const response = await fetch('https://backend.dambel.dev/api/count');
            const data = await response.json();
            setTotalViews(viewFormat(data.totalViews))
        } catch (error) {
            console.error('Error fetching view count:', error);
        }
    }, []);

    const showViewsToday = useCallback( async () => {
        try {
            const response = await fetch('https://backend.dambel.dev/api/counttoday');
            const data = await response.json();
            setTodayViews(viewFormat(data.totalViewsToday))
        } catch (error) {
            console.error('Error fetching view count:', error);
        }
    }, []);



    const deleteMessage = useCallback( async (messageId) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this message?");
        if (!isConfirmed) {
            return; // User did not confirm
        }
    
        try {
            const response = await fetch(`https://backend.dambel.dev/api/messages/${messageId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
    
            if (response.ok) {
                console.log("Message deleted successfully");
                window.location.reload();
            } else {
                throw new Error('Failed to delete the message');
            }
        } catch (error) {
            console.error('Error deleting message:', error);
        }
    }, []);

    const createMessageElement = (content, isButton = false, onClickCallback = null) => {
        const messageElement = document.createElement('div');
        messageElement.className = "message_element";

        if (isButton && onClickCallback) {
            const button = document.createElement('button');
            button.innerHTML = '<span className="fa-solid fa-trash trash_mess"></span>';
            button.onclick = () => onClickCallback();
            messageElement.appendChild(button);
        } else {
            const paddingDiv = document.createElement('div');
            paddingDiv.className = "padding_mess";
            paddingDiv.innerHTML = content; 
            messageElement.appendChild(paddingDiv);
        }

        return messageElement;
    };

    const showAllMessages = useCallback(async () => {
        try {
            const response = await fetch('https://backend.dambel.dev/api/messages');
            const messages = await response.json();
            
            const messagesTable = document.getElementById('messages_table');
            
            if(messagesTable){
                messagesTable.innerHTML = '';
            }
            

            messages.forEach(message => {
                const row = document.createElement('div');
                row.className = 'message_row';
    
                const idDiv = createMessageElement(message.id.toString());
                const subjectDiv = createMessageElement(message.subject);
                const emailDiv = createMessageElement(message.email);
                const messageDiv = createMessageElement(message.message);
                const delDiv = createMessageElement('', true, () => deleteMessage(message.id));

                // Append divs to the row
                row.appendChild(idDiv);
                row.appendChild(subjectDiv);
                row.appendChild(emailDiv);
                row.appendChild(messageDiv);
                row.appendChild(delDiv);

                if(messagesTable){
                    messagesTable.appendChild(row);
                }
            });
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    }, [deleteMessage]);
    
    useEffect(() => {
        showTotalViews();
        showViewsToday();
        showAllMessages();
    }, [showTotalViews, showViewsToday, showAllMessages, deleteMessage]);

    const logout = () => {
        fetch('https://backend.dambel.dev/api/logout', { credentials: 'include' })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Logout failed');
                }
                return response.json();
            })
            .then(() => {
                window.location.href = '/';
            })
            .catch(error => {
                console.error('Error logging out:', error);
            });
    }

    

    const viewFormat = (number) =>{
        return number.toLocaleString();
    }

    const relocateToHome = () =>{
        window.location.href = "/";
    }

    return (
       
        <div>
            {isLoggedIn &&
            <div className='admin_wrapper'>
                <div className="admin_header">
                    <div className='admin_header_left'>
                        <img onClick={() =>relocateToHome()} className="admin_img" src="/img/logo.webp" alt="logo"/>
                        <p>Admin Panel</p>
                    </div>
                    <div>
                        <button className='admin_logout' onClick={() => logout()}>
                            <span className="fa-solid fa-arrow-right-from-bracket logout_icon"></span> Logout
                        </button>
                    </div>
                    
                </div>
                <div className='admin_stats'>
                    <h1 className='admin_title'>Stats</h1>

                    <div className='stats_element'>
                        <p>Total number of views: </p> <span className="views_value">{totalViews}</span>
                    </div>
                    <div className='stats_element'>
                        <p>Number of views today: </p> <span className="views_value">{todayViews}</span>
                    </div>
                </div>


                <div className='admin_messages'>
                    <h1 className='admin_title'>Messages</h1>

                    <div className='messages'>
                        <div className='message_header'>
                            <div className='message_element message_element_header'>
                                ID
                            </div>
                            <div className='message_element message_element_header'>
                                Subject
                            </div>
                            <div className='message_element message_element_header'>
                                Email
                            </div>
                            <div className='message_element message_element_header'>
                                Message
                            </div>
                            <div className='message_element message_element_header'>
                                &nbsp;
                            </div>
                        </div>
                        <div id="messages_table">

                        </div>
                    </div>
                </div>
                

                
            </div>

                
            }
        </div>
       
    );
};

export default Admin;